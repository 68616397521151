<template>
  <div style="height: 100%, width: 100%">
    <b-modal v-model="state.modalShow" size="lg" :title="labelTitle" footer-class="footerClass" :id="localId"
      no-close-on-backdrop  content-class="shadow" modal-class="calendar-modal"
      @hidden="$emit('update:show', false)"
      scrollable
    >

      <b-alert variant="danger" dismissible :show="showError" @dismissed="dismissAlert">
        <font-awesome-icon :icon="['fas', 'triangle-exclamation']"/>&nbsp;&nbsp;{{ alertMsg }}
        <ul :show="showErrorDetail" class="mb-0">
          <template v-for="(item, index) in alertMsgDetails">
            <li :key="index">{{ item }}</li>
          </template>
        </ul>
      </b-alert>

      <ul class="day-list mb-3 pl-0">
        <template v-for="(task, index) in tasks">
          <b-row :key="index">
            <b-col cols="12" xl="4">
              <b-row class="mb-3">
                <b-col cols="12">
                  <b-form-group :label="$t('activity.field.startTime')" label-for="activity-startDate">
                    <b-form-datepicker v-model="task.startDate" class="mb-2" @input="dateTimeInputHandler($event, 'startDate', index)"
                      today-button
                      reset-button
                      close-button
                      hide-header
                      :label-today-button="$t('date.today')"
                      :label-reset-button="$t('date.reset')"
                      :label-close-button="$t('date.close')"
                      today-button-variant="primary"
                      reset-button-variant="danger" 
                      close-button-variant="secondary"
                      boundary="viewport"
                      :popper-opts="{ positionFixed: true }"
                    >
                      <template v-slot:button-content="{ }">
                        <font-awesome-icon :icon="['far', 'calendar-days']" />
                      </template>
                    </b-form-datepicker>
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <Timepicker v-model="task.startTime" locale="en"  @input="dateTimeInputHandler($event, 'startTime', index)">
                  </Timepicker>
                </b-col>
              </b-row>
            </b-col>
  
            <b-col cols="12" xl="4">
              <b-row class="mb-3">
                <b-col cols="12">
                  <b-form-group :label="$t('activity.field.closeTime')" label-for="activity-closeDate">
                    <b-form-datepicker v-model="task.closeDate" class="mb-2" @input="dateTimeInputHandler($event, 'closeDate', index)"
                      today-button
                      reset-button
                      close-button
                      hide-header
                      :label-today-button="$t('date.today')"
                      :label-reset-button="$t('date.reset')"
                      :label-close-button="$t('date.close')"
                      today-button-variant="primary"
                      reset-button-variant="danger" 
                      close-button-variant="secondary"
                      boundary="viewport"
                     :popper-opts="{ positionFixed: true }" 
                    >
                      <template v-slot:button-content="{ }">
                        <font-awesome-icon :icon="['far', 'calendar-days']" />
                      </template>
                    </b-form-datepicker>
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <Timepicker v-model="task.closeTime" @input="dateTimeInputHandler($event, 'closeTime', index)">
                  </Timepicker>
                </b-col>
              </b-row>
            </b-col>
            
            <b-col cols="12" xl="4">
              <b-form-group :label="$t('activity.field.duration')" label-for="activity-duration">
                <b-input-group>
                  <b-form-input id="activity-duration" type="text" @change="dateTimeInputHandler($event, 'duration', index)"
                    @keydown="onDurationKeyDown($event, index)" 
                    @keyup="onDurationKeyUp($event, index)"
                    v-model="task.value" 
                    >
                  </b-form-input>
                </b-input-group>
              </b-form-group>
              <div class="d-inline-block mr-1">
                <b-button :id="`SPLIT_TASK_ADD_${index}`" variant="secondary" @click="addEntry(index)">
                  <font-awesome-icon :icon="['far', 'plus']"/>
                  <b-popover
                    :target="`SPLIT_TASK_ADD_${index}`"
                    placement="top"
                    boundary="viewport"
                    triggers="hover"
                    :content="$t('button.add')">
                  </b-popover>
                </b-button>
              </div>
              <div class="d-inline-block mr-1">
                <b-button :id="`SPLIT_TASK_DELETE_${index}`" :disabled="index === 0" variant="secondary" @click="removeEntry(index)">
                  <b-popover
                    :target="`SPLIT_TASK_DELETE_${index}`"
                    placement="top"
                    boundary="viewport"
                    triggers="hover"
                    :content="$t('button.delete')">
                  </b-popover>
                  <font-awesome-icon :icon="['far', 'trash-can']"/>
                </b-button>
              </div>
            </b-col>
          </b-row>
        </template>
      </ul>
     
      <template v-slot:modal-footer="{ cancel }">
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <b-button :disabled="disableSave" size="sm" variant="success" @click="ok">{{ $t('button.ok') }}</b-button>
        <b-button size="sm" variant="danger" @click="cancel()">{{ $t('button.cancel') }}</b-button>
      </template>
    </b-modal>
    
       
    <TaskDateTimeDurationCalculation :show.sync="state.durationCalculationShow" 
      :resizeMode="durationCalculation.resizeMode"
      :defaultActionForNonWorkPrompt="null"
      :trigger="durationCalculation.trigger"
      :startDateStr="durationCalculation.startDateStr"
      :startTimeStr="durationCalculation.startTimeStr"
      :closeDateStr="durationCalculation.closeDateStr"
      :closeTimeStr="durationCalculation.closeTimeStr"
      :durationDisplay="durationCalculation.durationDisplay"
      :calendar.sync="durationCalculation.calendar"
      :projectScheduleFromStart="durationCalculation.projScheduleFromStart"
      :activityAutoScheduleMode="durationCalculation.activityAutoScheduleMode"
      :constraintType="durationCalculation.constraintType"
      :constraintDateStr="durationCalculation.constraintDateStr"
      :lockDuration="durationCalculation.lockDuration"
      :oldDateStr="durationCalculation.oldDateStr"
      :oldTimeStr="durationCalculation.oldTimeStr"
      :oldConstraintType="durationCalculation.oldConstraintType"
      :oldConstraintDateStr="durationCalculation.oldConstraintDateStr"
      :skipOutOfProjectDateCheck="durationCalculation.skipOutOfProjectDateCheck"
      :durationConversionOpts="durationConversionOpts"
      @success="durationCalculationOk"
      @cancel="durationCalculationCancel"
      @skip="durationCalculationCancel"
    />
    
  </div>
</template>

<script>
import { cloneDeep, debounce } from 'lodash';
import moment from 'moment-timezone';
moment.tz.setDefault('Etc/UTC');
import { DEFAULT_CALENDAR, TRIGGERS, extractDurationConversionOpts
} from '@/helpers/task-duration-process';
import { strRandom
  , processCalendar
  , transformCalendar
  , onDurationKeyDown
  , onDurationKeyUp 
} from '@/helpers';

export default {
  name: 'SplitTaskModal',
  components: {
    Timepicker: () => import('@/components/Calendar/Timepicker.vue'),
    TaskDateTimeDurationCalculation: () => import('@/components/Task/TaskDateTimeDurationCalculation')
  },
  props: {
    id:            { type: String, default: null },
    title:         { type: String, default: null },
    show:          { type: Boolean,required: true },
    calendar:      { type: Object, required: true },
    duration:      { type: Object, required: true }
  },
  data() {
    return {
      permissionName: 'CALENDAR',
      state: {
        modalShow: false,
        durationCalculationShow: false
      },
      alertMsg: null,
      alertMsgDetails: [],
      tasks: [],
      durationCalculation: {
        trigger: TRIGGERS.START_DATE
        , startDateStr: null
        , startTimeStr: null
        , closeDateStr: null
        , closeTimeStr: null
        , durationDisplay: null
        , calendar: DEFAULT_CALENDAR
        , projScheduleFromStart: true
        , activityAutoScheduleMode: true
        , constraintType: null
        , constraintDateStr: null
        , oldDateStr: null
        , oldTimeStr: null
        , lockDuration: false
        , skipOutOfProjectDateCheck: false
      },
      durationIndex: 0,
      durationConversionOpts: {}
    }
  },
  created() {
    this.getDurationConversionOpts();
    this.localId = `TASK_SPLIT_MODAL_${strRandom(5)}`;
  },
  beforeDestroy() {
    this.localId = null;
  },
  computed: {
    showError() {
      return this.alertMsg != null;
    },
    showErrorDetail() {
      return this.alertMsgDetails != null && this.alertMsgDetails.length > 0;
    },
    disableSave() {
      return this.alertMsg !== null || this.state.blockSave || this.tasks.length < 2;
    },
    labelTitle() {
      return this.title? this.title: this.$t('button.split_task');
    }
  },
  watch: {
    show(newValue) {
      if(newValue != this.state.modalShow) {
        this.state.modalShow = newValue;       
        this.alertMsg = null;
        this.alertMsgDetails.splice(0, this.alertMsgDetails.length);
        this.tasks = [cloneDeep(this.duration)];
      }
    }
  },
  methods: {
    ok() {
      this.errors.clear();
      this.alertMsg = null;
      this.alertMsgDetails.splice(0, this.alertMsgDetails.length);

      this.$emit('update:show', false);
      this.$emit('success', this.tasks);
    },
    dismissAlert() {
      this.alertMsg = null;
    },
    scrollToTop() {
      const elem = document.querySelector(`#${this.localId}___BV_modal_body_`);
      elem.scrollTop = 0;
    },
    dateTimeInputHandler: debounce(function(newValue, trigger, index) {
      this.durationIndex = index;
      let payload = {
        trigger: trigger != null? trigger : TRIGGERS.duration,
        resizeMode: false
      }
      if (TRIGGERS.START_DATE == trigger || TRIGGERS.START_TIME == trigger) {
        payload.oldDateStr = this.prevStartDateStr;
        payload.oldTimeStr = this.prevStartTimeStr;
      } else if (TRIGGERS.CLOSE_DATE == trigger || TRIGGERS.CLOSE_TIME == trigger) {
        payload.oldDateStr = this.prevCloseDateStr;
        payload.oldTimeStr = this.prevCloseTimeStr;
        payload.resizeMode = true;
      } else if (TRIGGERS.CONSTRAINT_TYPE == trigger || TRIGGERS.CONSTRAINT_DATE == trigger) {
        payload.oldConstraintType = this.prevConstraintType;
        payload.oldConstraintDateStr = this.prevConstraintDateStr;
      } else {
        payload.oldDateStr = null;
        payload.oldTimeStr = null;
      }
      this.initDurationCalculation(payload);

      //Update oldValue;
      if (TRIGGERS.START_DATE == trigger) {
        this.prevStartDateStr = newValue;
      } else if (TRIGGERS.START_TIME == trigger) {
         this.prevStartTimeStr = newValue;
      } else if (TRIGGERS.CLOSE_DATE == trigger) {
        this.prevCloseDateStr = newValue;
      } else if (TRIGGERS.CLOSE_TIME == trigger) {
        this.prevCloseTimeStr = newValue;
      } else if (TRIGGERS.CONSTRAINT_TYPE == trigger) {
        this.prevConstraintType = newValue;
      } else if (TRIGGERS.CONSTRAINT_DATE == trigger) {
        this.prevConstraintDateStr = newValue;
      }
    }, 300),
    
    durationCalculationOk({ startDateStr, startTimeStr, closeDateStr, closeTimeStr, durationDisplay, constraintType, constraintDateStr }) {
      this.tasks[this.durationIndex].startDate = startDateStr;
      this.tasks[this.durationIndex].startTime = startTimeStr;
      this.tasks[this.durationIndex].closeDate = closeDateStr;
      this.tasks[this.durationIndex].closeTime = closeTimeStr;
      this.tasks[this.durationIndex].value = durationDisplay;
      
      this.prevStartDateStr = startDateStr;
      this.prevStartTimeStr = startTimeStr;
      this.prevCloseDateStr = closeDateStr;
      this.prevCloseTimeStr = closeTimeStr;
      this.prevConstraintType = constraintType;
      this.prevConstraintDateStr = constraintDateStr;
    },
    durationCalculationCancel({ trigger, oldDateStr, oldTimeStr, oldConstraintType, oldConstraintDateStr }) {
      //Reset to previous value
      if (trigger == TRIGGERS.START_DATE || trigger == TRIGGERS.START_TIME) {
        this.tasks[this.durationIndex].startDate = oldDateStr;
        this.tasks[this.durationIndex].startTime = oldTimeStr;
        this.prevStartDateStr = oldDateStr;
        this.prevStartTimeStr = oldTimeStr;
      } else if (trigger == TRIGGERS.CLOSE_DATE || trigger == TRIGGERS.CLOSE_TIME) {
        this.tasks[this.durationIndex].closeDate = oldDateStr;
        this.tasks[this.durationIndex].closeTime = oldTimeStr;
        this.prevCloseDateStr = oldDateStr;
        this.prevCloseTimeStr = oldTimeStr;
      } else if (trigger == TRIGGERS.CONSTRAINT_TYPE || trigger == TRIGGERS.CONSTRAINT_DATE) {
        this.constraint.type = oldConstraintType;
        this.constraint.date = oldConstraintDateStr;
      } else if (trigger == TRIGGERS.TASK_SCHEDULE_MODE) {
        //Do nothing.
      }
      //Trigger 'duration' is not possible to emit 'cancel' event.
    },
    async initDurationCalculation({ trigger=TRIGGERS.DURATION, useExistingValues=false, oldDateStr=null, oldTimeStr=null
                                    , oldConstraintType=null, oldConstraintDateStr=null, skipOutOfProjectDateCheck=null,
                                    resizeMode=false } = {}) {
      if (useExistingValues !== true) {
        this.durationCalculation.trigger = trigger;
        this.durationCalculation.startDateStr = this.tasks[this.durationIndex].startDate;
        this.durationCalculation.startTimeStr = this.tasks[this.durationIndex].startTime;
        this.durationCalculation.closeDateStr = this.tasks[this.durationIndex].closeDate;
        this.durationCalculation.closeTimeStr = this.tasks[this.durationIndex].closeTime;
        this.durationCalculation.durationDisplay = this.tasks[this.durationIndex].value;
        this.durationCalculation.resizeMode = resizeMode;
        
        if (this.calendar == null) {
          this.calendar = cloneDeep(DEFAULT_CALENDAR);
        }
        this.durationCalculation.calendar = this.calendar;
        this.durationCalculation.oldDateStr = oldDateStr;
        this.durationCalculation.oldTimeStr = oldTimeStr;
        this.durationCalculation.oldConstraintType = oldConstraintType;
        this.durationCalculation.oldConstraintDateStr = oldConstraintDateStr;
      }
      //Used when calendarChange event is triggered. Pass along the 'session' skipOutOfProjectDateCheck state before resuming the session.
      if (skipOutOfProjectDateCheck != null) {
        this.durationCalculation.skipOutOfProjectDateCheck = skipOutOfProjectDateCheck;
      }
      this.state.durationCalculationShow = true; //start the calculation.
    },
    addEntry(index) {
      const copyItem = cloneDeep(this.tasks[index]);
      
      this.tasks.splice(index, 0, copyItem);
    },
    removeEntry(index) {
      this.tasks.splice(index, 1);
    },
    onDurationKeyDown(event) {
      onDurationKeyDown(event, false);
    },
    onDurationKeyUp(event, index) {
      this.tasks[index].value = onDurationKeyUp(event, this.tasks[index].value);
    },
    getDurationConversionOpts() {
      return this.$store.dispatch('data/configSchedule').then(value => {
        this.durationConversionOpts = extractDurationConversionOpts(value);
      })
      .catch(e => {
        console.error(e); //eslint-disable-line no-console
      });
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "@/assets/scss/style.scss";
  
  .calendar-modal {
    .exception-grid-height {
      height: 250px;
      min-height: 250px;
      margin-bottom: 10px;
    }
    .title {
      font-size: 1.2em;
      span {
        display: inline-block;
        line-height: 2;
      }
    }
  }
</style>
